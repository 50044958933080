<template>
  <div class="mt-1">
       <span class="pointer checkbox-container mr-4">
          <span
              @click="onCheck()"
              :class="row.selection ? 'checkbox-container-active' : 'checkbox-container-inactive'"
          >
          </span>
       </span>
    <span :class="'time-class-label-style-inactive mt-4'">
         {{ title }}
       </span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: this,
    }
  },
  methods: {
    onCheck () {
      this.row.selection = !this.row.selection;
      this.$emit('on:checkbox-selected', this.row);
    }
  }
}
</script>
<style scoped>
.checkbox-container {
  padding: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #d6d6d6;
  border-radius: 3px;
}

.checkbox-container-active {
  padding: 0px 11px 0px 5px;
  background: #00A5D8 0% 0% no-repeat padding-box;
  border-radius: 3px;
  margin-bottom: 8px;
}

.checkbox-container-active:hover {
  background: #057799 0% 0% no-repeat padding-box;
}

.checkbox-container-inactive:hover {
  background: #bce6f3 0% 0% no-repeat padding-box;
}

.checkbox-container-inactive {
  padding: 0px 10px 1px 5px;
  background: transparent;
  border-radius: 3px;
}


.time-class-label-style-active {
  font: normal normal 500 18px Avenir;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}

.time-class-label-style-inactive {
  font: normal normal 300 17px Avenir;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}
</style>